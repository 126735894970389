.modal__wrap {
	position: fixed;
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 10000000;
	/* overflow-x: hidden; */
	background-color: rgba(3, 3, 3, 0.964);
	pointer-events: none;
	opacity: 0;

	z-index: 9999;
	overflow: hidden !important;
	visibility: hidden;
}

.close_btn {
	margin-left: auto;
	background: #e9e9e9;
	padding: 0 0.3em !important;
	border: none;
	outline: none;
}

.visible {
	pointer-events: auto;
	opacity: 1;
	transition: all 300ms ease-in-out;
	visibility: visible;
}

.modal {
	display: flex;
	flex-direction: column;
	max-width: 490px;
	width: 100%;
	max-height: 90vh;
	min-width: 400px;
	margin: 0 auto;
	z-index: 9999;
	padding: 2.5em;
	background-color: #fff;
	align-self: center;
	box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
	opacity: 0;
	transform: scale(0.1);
	transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
	transform: translateY(-50%);
	overflow-y: auto;
}

.modal2 {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	z-index: 100000000;
	align-self: center;
	opacity: 0;
	transform: scale(0.1);
	transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
	transform: translateY(-50%);
	overflow-y: auto;
}

.visible .modal {
	opacity: 1;
	transform: translateY(0%);
	transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
}

.visible .modal2 {
	opacity: 1;
	transform: translateY(0%);
	transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
}

.close__btn {
	background-color: gray;
	padding: 2px;
	margin-left: auto;
	font-size: 23px;
	padding: 0;
}

@media (max-width: 595px) {
	.modal {
		max-width: 340px;
		min-width: 340px;
	}
}
