@import url("https://fonts.googleapis.com/css2?family=Lexend+Zetta&family=Montserrat:wght@200;300;500;700&display=swap");
:root {
	--orange: #ff7800;
	--black: #130f40;
	--light-color: #666;
	--box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
	--border: 0.2rem solid rgba(0, 0, 0, 0.1);
	--outline: 0.1rem solid rgba(0, 0, 0, 0.1);
	--outline-hover: 0.2rem solid #fff;
}

* {
	font-family: "Lexend Zetta", sans-serif;
	font-family: "Montserrat", sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
	border: none;
	text-decoration: none;
}

html {
	font-size: 62.5%;
	overflow-x: hidden;
	scroll-behavior: smooth;
	scroll-padding-top: 7rem;
}

body {
	/* background-image: linear-gradient(rgba(60, 25, 96, 1), rgba(161, 38, 105, 1)),
		url(/image/Rectangle.png); */
	/* background: linear-gradient(98.04deg, #3C1960 -9.26%, #A12669 58.73%); */
}

section {
	padding: 2rem 9%;
}

.heading {
	text-align: center;
	padding: 2rem 0;
	padding-bottom: 3rem;
	font-size: 3.5rem;
	color: #ffffff;
	font-family: "Montserrat";
}

.heading2 {
	padding: 2rem 0;
	padding-bottom: 3rem;
	font-size: 3.5rem;
	color: #ffffff;
	font-family: "Montserrat";
}

.btn {
	padding: 9px 40px;
	text-decoration: none;
	font-family: "Montserrat";
	color: #fff;
	display: inline-block;
	font-size: 2rem;
	margin: 0px 0;
	border-radius: 15px;
	border: 2px solid #fff;
	background: linear-gradient(
		98.04deg,
		#3c1960 -9.26%,
		#a12669 58.73%,
		#fec261 124.53%
	);
}

.btn:hover {
	background: #130f40;
	color: #fff;
}

.header {
	position: fixed !important;
	top: 36px !important;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	z-index: 99 !important;
}
#chk1 {
	display: none;
}

header.sticky {
	padding: 0px 10px;
	background: #fff;
}

header .search-box {
	flex: 1;
	position: relative;
}

.search-box input {
	width: 50%;
	height: 40px;
	border: 2px solid #d2d0d0;
	outline: #a12669;
	background: transparent;
	border-radius: 30px;
	/* color: #fff; */
	font-size: 18px;
	padding: 10px;
}

.fa-arrow-right {
	color: #fff;
}

.search-box button {
	cursor: pointer;
	width: 40px;
	height: 40px;
	border-radius: 30px;
	border: none;
	position: absolute;
	top: 0;
	right: 0;
	transform: scale(0.9);
	background: #a12669;
}

header.sticky .logo,
header.sticky ul li a {
	color: #a12669;
}

header ul {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

header ul li {
	position: relative;
	list-style: none;
}

header ul li a {
	position: relative;
	margin: 0px 50px;
	text-decoration: none;
	color: #fff;
	letter-spacing: 2px;
	font-weight: 500;
}

header ul li a:hover {
	border-bottom: 2px solid cadetblue;
}

header ul li button {
	padding: 10px 20px;
	border-radius: 20px;
	cursor: pointer;
	background: #a12669;
	color: #fff;
	border: none;
}

header ul li button:hover {
	padding: 10px 20px;
	border-radius: 20px;
	cursor: pointer;
	background: #fff;
	color: #a12669;
	border: none;
}

header .menu {
	font-size: 2.5em;
	display: none;
}

@media (max-width: 1000px) {
	.search-box button {
		position: absolute;
	}

	header ul {
		position: fixed;
		top: 100px;
		right: -100%;
		background: rgba(0, 0, 0, 0.5);
		height: calc(100vh - 100px);
		width: 50%;
		flex-direction: column;
		align-items: center;
	}

	header .menu {
		display: block;
		width: 100px;
		text-align: center;
	}

	#chk1:checked ~ ul {
		right: 0;
	}
}

@media (max-width: 600px) {
	header .logo {
		font-size: 10px;
		margin-left: 8px;
	}

	header ul {
		width: 100%;
	}
}
/* home start  */
.home {
	/* background: url("/bg2.png") no-repeat; */
	background-position: center;
	background-size: cover;
	padding-top: 20rem;
	padding-bottom: 20rem;
	height: 100vh;
}

.home .content {
	/* text-align: center; */
	width: 80rem;
}

.home .content h3 {
	color: #ffffff;
	font-size: 5rem;
	font-weight: 400;
	font-family: "Lexend Zetta";
	margin-bottom: 20px;
}

.home .content pre {
	color: #ffffff;
	font-size: 1.7rem;
	font-family: "Montserrat";
	padding: 1rem 0;
	line-height: 1.8;
}
/* home end  */

/* video start  */
.video .video-slider {
	padding: 1rem;
}

.video .video-slider .box img {
	height: 100%;
	width: 100%;
	border-radius: 5rem;
}

/* video end  */

/* Gallery start  */
.container {
	max-width: 100%;
	margin: 20px auto;
	text-align: center;
}

.container .image-container {
	columns: 3 250px;
	gap: 25px;
}

.container .image-container img {
	width: 100%;
}

/* Gallery end  */

.categories .box-container {
	display: grid;
	grid-template-rows: repeat(auto-fit, minmax(2rem, 1fr));
	gap: 1.5rem;
}

.categories .box-container .box {
	background: transparent;
	display: flex;
	justify-content: center;
	border-radius: 1rem;
	text-align: center;
}

.categories .box-container .box img {
	width: 50rem;
}

.categories .box-container .box h3 {
	font-size: 2rem;
	color: var(--black);
}

.categories .box-container .box p {
	font-size: 1rem;
	color: var(--light-color);
}
/* review trending start */

.review .review-slider .box {
	background: transparent;
	border-radius: 0.5rem;
}

.review .review-slider .box img {
	height: 20rem;
	width: 100%;
}

.review .review-slider .box p {
	padding: 0;
	line-height: 1.8;
	color: #fff;
	font-size: 1rem;
}

.review .review-slider .box h3 {
	padding-bottom: 0.5rem;
	color: #fff;
	font-size: 2rem;
	font-family: "Montserrat";
}
/* review trending end */

.blogs .box-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
	gap: 1.5rem;
}

.blogs .box-container .box {
	overflow: hidden;
	border-radius: 0.5rem;
	background: transparent;
	text-align: center;
}

.blogs .box-container .box img {
	height: 20rem;
	width: 100%;
	border-radius: 2rem;
	object-fit: cover;
}

.blogs .box-container .box .content h3 {
	line-height: 1.8;
	color: #fff;
	font-size: 2.2rem;
	padding: 0.5rem 0;
}

/* work section start */
.works {
	background: transparent;
}

.works .box-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
	gap: 1.5rem;
}

.works .box-container .box {
	padding: 1rem;
	align-items: center;
	text-align: center;
	background: #1d1429;
	border-radius: 1.5rem;
}
.works .box-container .box:hover {
	opacity: 0.5;
}
.works .box-container .box img {
	width: 20%;
	margin: 1rem;
	border-radius: 50%;
}

.works .box-container .box h1 {
	font-size: 2rem;
	color: #fff;
	padding: 1rem 0;
	font-family: "Montserrat";
}

.works .box-container .box p {
	line-height: 1.8;
	font-size: 1rem;
	color: #fff;
	font-family: "Montserrat";
	padding: 1.5rem;
}

.works .box-container .box1 h1 {
	font-size: 2rem;
	color: #fff;
	padding: 1rem 0;
	font-family: "Montserrat";
}

.works .box-container .box1 p {
	line-height: 1.8;
	font-size: 1rem;
	color: #fff;
	padding: 1rem 0;
	font-family: "Montserrat";
}

/* work section end */

/* chart start */

.chart {
	background: transparent;
}
.chart img {
	object-fit: cover;
	width: 100%;
}
/* chart end */

/* doller section start */
.doller {
	background: transparent;
}

.doller .box-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
	gap: 1.5rem;
}

.doller .box-container .box {
	padding: 1rem;
	align-items: center;
	text-align: center;
	background: transparent;
	border-radius: 1.5rem;
}

.doller .box-container .box:hover {
	opacity: 0.5;
}

.doller .box-container .box h1 {
	font-size: 3rem;
	color: #fff;
	padding: 1rem 0;
	font-family: "Montserrat";
}

.doller .box-container .box p {
	line-height: 1.8;
	font-size: 1.3rem;
	color: #fff;
	font-family: "Montserrat";
	padding: 1.5rem;
}

/* doller section end */
/* footer section start */
.footer {
	background: #111111;
}

.footer .box-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
	gap: 1.5rem;
}

.footer .box-container .box h3 {
	font-size: 2rem;
	color: #fff;
	padding: 1rem 0;
}
.footer .box-container .box h2 {
	font-size: 1.5rem;
	color: #a12669;
	padding: 1rem 0;
	font-family: "Montserrat";
}

.footer .box-container .box .links {
	display: block;
	font-size: 1rem;
	color: #fff;
	padding: 1rem 0;
	font-family: "Montserrat";
}

.footer .box-container .box p {
	line-height: 1.8;
	font-size: 1rem;
	color: #fff;
	padding: 1rem 0;
}

.footer .box-container .box .share a {
	height: 4rem;
	width: 4rem;
	line-height: 4rem;
	border-radius: 0.5rem;
	font-size: 2rem;
	color: var(--black);
	margin-right: 0.2rem;
	background: #eee;
	text-align: center;
}

.footer .box-container .box .share a:hover {
	background: var(--orange);
	color: #fff;
}

.footer .box .email {
	width: 40%;
	margin: 0.7rem 0.3rem 5rem 32rem;
	padding: 1.3rem;
	border-radius: 1.5rem;
	background: #eee;
	font-size: 1.6rem;
	color: var(--black);
	text-transform: none;
}

.footer .credit {
	text-align: right;
	margin-top: 2rem;
	padding: 1rem;
	padding-top: 2.5rem;
	font-size: 1rem;
	color: #fff;
	border-top: var(--border);
}
/* footer section end */

/* media queries  */

@media (max-width: 991px) {
	html {
		font-size: 55%;
	}

	.header {
		padding: 2rem;
	}

	section {
		padding: 2rem;
	}
}

@media (max-width: 768px) {
	#menu-btn {
		display: inline-block;
	}

	.header .search-form {
		width: 90%;
	}

	.header .navbar {
		position: absolute;
		top: 110%;
		right: -110%;
		width: 30rem;
		box-shadow: var(--box-shadow);
		border-radius: 0.5rem;
		background: #fff;
	}

	.header .navbar.active {
		right: 2rem;
	}

	.header .navbar a {
		font-size: 2rem;
		margin: 2rem 2.5rem;
		display: block;
	}
}

@media (max-width: 450px) {
	html {
		font-size: 50%;
	}

	.heading {
		font-size: 2.5rem;
	}
	.heading2 {
		font-size: 2.5rem;
	}

	.footer {
		text-align: center;
	}

	.footer .box-container .box .payment-img {
		margin: 2rem auto;
	}
}

@import url("https://fonts.googleapis.com/css2?family=Lexend+Zetta&family=Montserrat:wght@200;300;500;700&display=swap");
:root {
	--orange: #ff7800;
	--black: #130f40;
	--light-color: #666;
	--box-shadow: 0 0.5rem 1.5rem rgba(0, 0, 0, 0.1);
	--border: 0.2rem solid rgba(0, 0, 0, 0.1);
	--outline: 0.1rem solid rgba(0, 0, 0, 0.1);
	--outline-hover: 0.2rem solid #fff;
}

* {
	font-family: "Lexend Zetta", sans-serif;
	font-family: "Montserrat", sans-serif;
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
	border: none;
	text-decoration: none;
}

html {
	font-size: 62.5%;
	overflow-x: hidden;
	scroll-behavior: smooth;
	scroll-padding-top: 7rem;
}

body {
	/* background-image: linear-gradient(rgba(60, 25, 96, 1), rgba(161, 38, 105, 1)),
		url(/image/Rectangle.png); */
	/* background: linear-gradient(98.04deg, #3C1960 -9.26%, #A12669 58.73%); */
}

section {
	padding: 2rem 9%;
}

.heading {
	text-align: center;
	padding: 2rem 0;
	padding-bottom: 3rem;
	font-size: 3.5rem;
	color: #ffffff;
	font-family: "Montserrat";
}

.heading2 {
	padding: 2rem 0;
	padding-bottom: 3rem;
	font-size: 3.5rem;
	color: #ffffff;
	font-family: "Montserrat";
}

.btn {
	padding: 9px 40px;
	text-decoration: none;
	font-family: "Montserrat";
	color: #fff;
	display: inline-block;
	font-size: 2rem;
	margin: 0px 0;
	border-radius: 15px;
	border: 2px solid #fff;
	background: linear-gradient(
		98.04deg,
		#3c1960 -9.26%,
		#a12669 58.73%,
		#fec261 124.53%
	);
}

.btn:hover {
	background: #130f40;
	color: #fff;
}

.header {
	position: fixed !important;
	top: 0;
	left: 0;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 20px;
	z-index: 100000;
}
#chk1 {
	display: none;
}

header.sticky {
	padding: 0px 10px;
	background: #fff;
}

header .logo {
	position: relative;
	font-weight: 600;
	color: #fff;
	text-decoration: none;
	font-size: 4em;
	letter-spacing: 2px;
	padding: 20px;
}

header .search-box {
	flex: 1;
	position: relative;
}

.search-box input {
	width: 100%;
	height: 40px;
	border: 2px solid #d2d0d0;
	outline: #a12669;
	background: transparent;
	border-radius: 30px;
	/* color: #fff; */
	font-size: 18px;
	padding: 10px;
}

.fa-arrow-right {
	color: #fff;
}

.search-box button {
	cursor: pointer;
	width: 40px;
	height: 40px;
	border-radius: 30px;
	border: none;
	position: absolute;
	top: 0;
	right: 0;
	transform: scale(0.9);
	background: #a12669;
}

header.sticky .logo,
header.sticky ul li a {
	color: #a12669;
}

header ul {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
}

header ul li {
	position: relative;
	list-style: none;
}

header ul li a {
	position: relative;
	margin: 0px 50px;
	text-decoration: none;
	color: #fff;
	letter-spacing: 2px;
	font-weight: 500;
}

header ul li a:hover {
	border-bottom: 2px solid cadetblue;
}

header ul li button {
	padding: 10px 20px;
	border-radius: 20px;
	cursor: pointer;
	background: #a12669;
	color: #fff;
	border: none;
}

header ul li button:hover {
	padding: 10px 20px;
	border-radius: 20px;
	cursor: pointer;
	background: #fff;
	color: #a12669;
	border: none;
}

header .menu {
	font-size: 2.5em;
	display: none;
}

@media (max-width: 1000px) {
	.search-box button {
		position: absolute;
	}

	header ul {
		position: fixed;
		top: 100px;
		right: -100%;
		background: rgba(0, 0, 0, 0.5);
		height: calc(100vh - 100px);
		width: 50%;
		flex-direction: column;
		align-items: center;
	}

	header .menu {
		display: block;
		width: 100px;
		text-align: center;
	}

	#chk1:checked ~ ul {
		right: 0;
	}
}

@media (max-width: 600px) {
	header .logo {
		font-size: 10px;
		margin-left: 8px;
	}

	header ul {
		width: 100%;
	}
}

/* Gallery start  */
.product-container {
	max-width: 100%;
	margin: 10rem auto;
	display: flex;
	/* text-align: center; */
}

.product-container .left-column {
	width: 50%;
}
.product-container .right-column {
	width: 50%;
}

.product-container .active {
	margin-bottom: 10px;
	border-radius: 5px;
	width: 90%;
}
.right-column h1 {
	font-size: 48px;
	font-weight: 700;
	font-family: "Montserrat";
	margin: 10px 0px;
	color: #a12669;
}
.right-column p {
	font-size: 19px;
	margin: 10px 0px;
	font-family: "Montserrat";
	font-weight: 500;
	color: #fff;
}
.right-column h3 {
	font-size: 32px;
	font-weight: 600;
	font-family: "Montserrat";
	margin: 10px 0px;
	color: #fff;
}
.right-column h6 {
	font-size: 12px;
	font-weight: 400;
	font-family: "Montserrat";
	margin: 10px 0px;
	color: #a12669;
}
.right-column h6 span {
	font-size: 12px;
	font-weight: 400;
	font-family: "Montserrat";
	margin: 10px 0px;
	color: #fff;
}
.right-column .fab {
	font-size: 12px;
	color: #fff;
	padding: 5px;
}
.right-column .select {
	padding: 8px 20%;
	border-radius: 10px;
	color: #adadad;
	margin: 20px 20px 20px 0px;
}

/* Gallery end  */

.blogs .box-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
	gap: 1.5rem;
}

.blogs .box-container .box {
	overflow: hidden;
	border-radius: 0.5rem;
	background: transparent;
	text-align: center;
}

.blogs .box-container .box img {
	height: 20rem;
	width: 100%;
	border-radius: 2rem;
	object-fit: cover;
}

.blogs .box-container .box .content h3 {
	line-height: 1.8;
	color: #fff;
	font-size: 2.2rem;
	padding: 0.5rem 0;
}

/* footer section start */
.footer {
	background: #111111;
}

.footer .box-container {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
	gap: 1.5rem;
}

.footer .box-container .box h3 {
	font-size: 2rem;
	color: #fff;
	padding: 1rem 0;
}
.footer .box-container .box h2 {
	font-size: 1.5rem;
	color: #a12669;
	padding: 1rem 0;
	font-family: "Montserrat";
}

.footer .box-container .box .links {
	display: block;
	font-size: 1rem;
	color: #fff;
	padding: 1rem 0;
	font-family: "Montserrat";
}

.footer .box-container .box p {
	line-height: 1.8;
	font-size: 1rem;
	color: #fff;
	padding: 1rem 0;
}

.footer .box-container .box .share a {
	height: 4rem;
	width: 4rem;
	line-height: 4rem;
	border-radius: 0.5rem;
	font-size: 2rem;
	color: #000000;
	margin-right: 0.2rem;
	background: #eee;
	text-align: center;
}

.footer .box .email {
	width: 40%;
	margin: 0.7rem 0.3rem 5rem 32rem;
	padding: 1.3rem;
	border-radius: 1.5rem;
	background: #eee;
	font-size: 1.6rem;
	color: #000000;
	text-transform: none;
}

.footer .credit {
	text-align: right;
	margin-top: 2rem;
	padding: 1rem;
	padding-top: 2.5rem;
	font-size: 1rem;
	color: #fff;
}
/* footer section end */

/* media queries  */

@media (max-width: 991px) {
	html {
		font-size: 55%;
	}

	.header {
		padding: 2rem;
	}

	section {
		padding: 2rem;
	}
}

@media (max-width: 768px) {
	#menu-btn {
		display: inline-block;
	}

	.header .search-form {
		width: 90%;
	}

	.header .navbar {
		position: absolute;
		top: 110%;
		right: -110%;
		width: 30rem;
		box-shadow: var(--box-shadow);
		border-radius: 0.5rem;
		background: #fff;
	}

	.header .navbar.active {
		right: 2rem;
	}

	.header .navbar a {
		font-size: 2rem;
		margin: 2rem 2.5rem;
		display: block;
	}
}

@media (max-width: 450px) {
	html {
		font-size: 50%;
	}

	.heading {
		font-size: 2.5rem;
	}
	.heading2 {
		font-size: 2.5rem;
	}

	.footer {
		text-align: center;
	}

	.footer .box-container .box .payment-img {
		margin: 2rem auto;
	}
}
