.text-first {
	color: #000
}

.text-second {
	color: #00A877
}

.bg-first {
	background-color: #e75480
}

.txt-light {
	color: #e75480
}

.bg-second {
	background-color: #444
}

.border-second {
	border: 2px solid #444
}

.txt:hover {
	color: #e75480 !important
}


.txt2:hover {
	color: #e75480 !important
}

.mainText {
	font-size: 14px !important;
	line-height: 1.7em;
	color: #444
}

.profile-btn,
.profile-btn2,
.profile-btn3 {
	padding: 0.5em 3em;
	font-size: 15px;
	box-sizing: border-box;
	/* Include border and padding in the total width/height */
}

.mainRound {
	border-radius: 4px;
}

.shopBorder {
	border: 0.5px solid rgb(255, 255, 255, 0.1);
}

.profile-btn {
	background: #e75480;
	color: white;
	font-weight: bold;
	border: 2px solid #e75480;
}

.profile-btn:hover {
	background: #e92660 !important;

}

.profile-btn3 {
	background: #0099fa;
	color: white;
	font-weight: bold;
	border: 2px solid #0099fa;
}

.profile-btn3:hover {
	background: #0157f8 !important;
	cursor: pointer;

}

.loader {
	border: 6px solid #a12669;
	/* Light grey */
	border-top: 6px solid #e3e3e3;
	/* Blue */
	border-radius: 50%;
	width: 40px;
	text-align: center;
	height: 40px;
	animation: spin 1s linear infinite;
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.profile-btn2 {
	border: 1px solid #e3e3e3;

	color: black;
	font-weight: bold;

}

.profile-btn2:hover {
	border: 1px solid #e3e3e3;
	background: #e3e3e3;
	color: black;
	font-weight: bold;

}

.styled-table {
	border-collapse: collapse;
	margin: 25px 0;
	font-size: 16px;
	font-family: sans-serif;
	width: 100%;
}

.styled-table thead tr {
	background: linear-gradient(98.04deg, #3c1960 -9.26%, #a12669 58.73%);

	color: #ffffff;
	text-align: left;
}

.styled-table th,
.styled-table td {
	padding: 12px 15px;
}

.styled-table tbody tr {
	border-bottom: 1px solid #dddddd;
}

.styled-table tbody tr:nth-of-type(even) {
	background-color: #f3f3f3;
}

.styled-table tbody tr.active-row {
	font-weight: bold;
	color: #009879;
}

.form-box {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-bottom: 10px;
}

.form-box label {
	font-size: 14px;
	font-weight: bold;
}

.form-box input,
.form-box textarea,
.form-box select {
	padding: 14px;
	font-size: 12px;
	width: 100%;
	margin-bottom: 8px;
	margin-top: 12px;
	border: 1px solid rgb(220, 219, 219);
}

.profile-container {
	max-width: 700px;
	width: 90%;
	margin: 0 auto;
}

.main-container {
	max-width: 1100px;
	width: 90%;
	margin: 0 auto;
}

.nft-desc {
	display: flex;
	gap: 2em;
	margin-top: 36px;
	margin-bottom: 16px;
}

.sell-input {
	display: flex;
	gap: 2em;
}

.custom-file-input::-webkit-file-upload-button {
	visibility: hidden;
}

.custom-file-input::before {
	content: "upload";
	display: inline-block;
	background: linear-gradient(98.04deg, #3c1960 -9.26%, #a12669 58.73%);
	border-radius: 13px;
	padding: 10px 22px;
	outline: none;
	white-space: nowrap;
	-webkit-user-select: none;
	cursor: pointer;
	color: white;
	font-weight: 700;
	font-size: 10pt;
}

.custom-file-input:hover::before {
	border-color: transparent;
}

.custom-file-input:active::before {
	background: -webkit-linear-gradient(98.04deg, #3c1960 -9.26%, #a12669 58.73%);
}

.cat-img {
	position: relative;
}

.cat-img .input {
	position: absolute;
	bottom: 10px;
	right: 3px;
}

label.label input[type="file"] {
	position: absolute;
	top: -1000px;
}

.label {
	cursor: pointer;
	border: 0px solid #cccccc;
	border-radius: 100%;
	padding: 10px 12px;
	margin: 5px;
	background: linear-gradient(98.04deg, #3c1960 -9.26%, #a12669 58.73%);
	display: inline-block;
}

.label:hover {
	background: #5cbd95;
}

.label:active {
	background: #9fa1a0;
}

.label:invalid+span {
	color: #000000;
}

.label:valid+span {
	color: #ffffff;
}

button.swal-button.swal-button--confirm {
	background: #444;
	color: #fff;
	text-decoration: none;
	padding: 6px 10px;
	display: block;
	width: 30%;
	margin: 0 auto;
	border-radius: 0px;
}

button.swal-button.swal-button--confirm:hover {
	background: #4472c7;
	border-color: #4472c7;
}

.swal-button:focus {
	box-shadow: none;
}

.swal-button-container {
	margin: 5px;
	display: block;
	position: relative;
	text-align: center;
}

.swal-modal {
	border-radius: 0px;
}

.nft-collection {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 3em;
	margin-top: 3em;
}


.swal-text {
	text-align: center !important;
	font-weight: normal !important;
	font-size: 14px !important;
}

.swal-title {
	text-align: center !important;
	font-weight: normal !important;
	font-size: 22px !important;
}




.sold-nft {
	position: relative;
}

.imgs:hover {
	opacity: 0.5
}



.site-container {
	max-width: 1500px;
	width: 90%;
	margin: 0 auto;
}

.bannerText {
	font-family: "Quattrocento", serif !important;
}

.mainBorder {
	border: 0.5px solid #e6e6e6;

}

.searchBorder {
	border-bottom: 2px solid #a3a3a3;

}

.sold-box {
	z-index: 10;
	position: absolute;
	top: 15%;
	left: 20%;
	transform: translate(50%, -50%);
	border: 3px solid red;
	border-radius: 50%;
	width: max-content;
	padding: 1em 1em;
	font-size: 30px;
	font-weight: bold;
	color: red;
	transform: rotate(-10deg);
}

.mainContainer {
	max-width: 1400px;
	width: 95%;
	margin: 0em auto;
}

.auction-box {
	z-index: 10;

	border: 4px solid red;
	width: max-content;
	padding: 0.2em 1em;
	font-size: 22px;
	font-weight: bold;
	color: red;
}

.searchBox {
	display: flex;
	align-items: center;
}

.searchBox input {
	background-color: transparent !important;
	color: #222 !important;
	width: 100%;

	font-size: 14px;
	padding: 0.5em;
	width: 100%;
}

.searchBox i {
	padding: 0.5em;
	font-size: 16px;
	border-radius: 0 30px 30px 0;
}

.nft-collections {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
	gap: 2em;
}

.splide,
.review {
	padding: 0 !important;
}

@media (max-width: 600px) {
	.headerBox {
		flex-direction: column;
		text-align: center;
		padding: "1em";
	}

	.productDetail {
		flex-direction: column;
	}

	.headerNft {
		padding: 3em 0;
		width: 100%;
	}

	.image-container {
		grid-template-columns: 1fr !important;
	}

	/* .marketSearch {
		flex-direction: column;
	}

	.marketDataSearch {
		width: 100% !important;
		margin-left: 0px;
	} */
}

@media (max-width: 1020px) {
	.nft-collection {
		grid-template-columns: 1fr 1fr 1fr !important;
	}
}

@media (max-width: 720px) {

	.image-container,
	.nft-collection {
		grid-template-columns: 1fr 1fr !important;
	}

	.nft-collections {
		grid-template-columns: 1fr 1fr;
	}

	.productDetail,
	.flexFooter {
		flex-direction: column;
	}

	.flexFooter .copyright {
		margin-top: 20px;
	}

	.menuMob {
		display: none;
	}
}

@media (max-width: 520px) {

	.image-container,
	.nft-collection {
		grid-template-columns: 1fr !important;
	}

	.nft-collections {
		grid-template-columns: 1fr 1fr;
	}
}

.cmsPage {
	text-align: left;
}

.cmsPage h1 {
	font-size: 32px;
	font-weight: bold;
	margin-top: 0.5em;
	margin-bottom: 0.3em;
}

.cmsPage h2 {
	font-size: 28px;
	font-weight: bold;
	margin-top: 0.5em;
	margin-bottom: 0.3em;
}

.cmsPage h3 {
	font-size: 24px;
	font-weight: bold;
	margin-top: 0.5em;
	margin-bottom: 0.3em;
}

.cmsPage h4 {
	font-size: 20px;
	font-weight: bold;
	margin-top: 0.5em;
	margin-bottom: 0.3em;
}

.cmsPage h5 {
	font-size: 16px;
	font-weight: bold;
	margin-top: 0.5em;
	margin-bottom: 0.3em;
}

.cmsPage h6 {
	font-size: 12px;
	font-weight: bold;
	margin-top: 0.5em;
	margin-bottom: 0.3em;
}

.cmsPage p {
	font-size: 17px;
	line-height: 2.3em;
	color: #444;
}

.cmsPage a {
	color: blue;
	text-decoration: underline;
}

.cmsPage ul {
	font-size: 16px;
	list-style-type: disc;
	margin-left: 2em;
	margin-bottom: 1em
}

.cmsPage ol {
	font-size: 16px;
	list-style-type: lower-alpha;
	margin-left: 2em;
	margin-bottom: 1em
}

.cmsPage ol li {
	margin-top: 1em;
}

.label-toggle {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 20px;
}

.input-toggle {
	opacity: 0;
	width: 0;
	height: 0;
}

.span-toggle {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #2c3e50;
}

.span-toggle:before {
	position: absolute;
	content: "";
	height: 15px;
	width: 25px;
	left: 3px;
	bottom: 2.6px;
	background-color: #fff;
}

.input-toggle:checked+.span-toggle {
	background-color: #00c853;
}

.input-toggle:checked+.span-toggle:before {
	transform: translateX(29px);
}